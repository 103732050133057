export const permissionLevels = [
  { label: 'Admin', value: 'admin' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Standard', value: 'user' },
  { label: 'Team Leader', value: 'teamLead' },
];

export const accessLevelPermissions = [
  { label: 'Admin', value: 'admin' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Standard', value: 'Standard' },
  { label: 'Team Leader', value: 'teamLead' },
];
export const userBulkActions = [
  // {
  //   title: 'Remove licence',
  //   key: 'removeLicence',
  // },
  // {
  //   title: 'Assign licence',
  //   key: 'assignLicence',
  // },
  // {
  //   title: 'Resend invite',
  //   key: 'resendInvite',
  // },
  {
    title: 'Mark as inactive',
    key: 'markAsInactive',
  },
  {
    title: 'Change Team',
    key: 'selectTeam',
  },
  {
    title: 'Change Office',
    key: 'selectOffice',
  },
  {
    title: 'Change Permission',
    key: 'selectPermission',
    isNotPrimary: true,
  },
  {
    title: 'Change Timezone',
    key: 'selectTimezone',
    isNotPrimary: true,
  },
];

export const userNameRegex = /^[a-zA-Z\s\-'\/.]+$/;
