import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from './style';
import { Button } from 'src/components/shared';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import MissingImage from './MissingImage';

const RoleplayCard = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  const showButton = (type) => {
    switch (type) {
      case 'default':
        return (
          <Button
            variant="outlined"
            color={'secondary'}
            onClick={() => history.push(`edit/${data?._id}/${data?.roleplayObj?.id}`)}
          >
            Default Roleplay
          </Button>
        );

      case 'modified':
        return (
          <Button
            variant="contained"
            color={'secondary'}
            onClick={() => history.push(`edit/${data?._id}/${data?.roleplayObj?.id}`)}
          >
            Modified Roleplay
          </Button>
        );

      default:
        return (
          <Button
            variant="contained"
            color={'secondary'}
            onClick={() => history.push(`add/${data?._id}`)}
          >
            Create roleplay
          </Button>
        );
    }
  };
  return (
    <Box key={data.id} className={classes.RoleplayCardRoot}>
      <Box className="card-left">
        {data?.headerImage ? (
          <img className="roleplay-img" src={data?.headerImage} alt="Roleplay Image" />
        ) : (
          <MissingImage title="Image not found" />
        )}
      </Box>

      <Box className="card-right">
        <Typography variant="h4" gutterBottom>
          {data?.title}
        </Typography>
        <Typography variant="body1" color="textSecondary">{`${data?.expert[0]?.fname || ''} ${
          data?.expert[0]?.lname || ''
        }`}</Typography>
        {showButton(data?.type)}
      </Box>
    </Box>
  );
};

RoleplayCard.propTypes = {
  data: PropTypes.object,
};

RoleplayCard.defaultProps = {
  data: {},
};

export default RoleplayCard;
