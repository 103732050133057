import React from 'react';
import useStyles, { HistoryItem } from './style';
import { Link } from 'react-router-dom';
import { HistorySlider } from '../../shared/HistorySlider.js/index.js';
import { removeHTML } from '../../utils/helper';
import MissingImage from '../../shared/MissingImage';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';

const WatchHistorySlider = ({ data }) => {
  const classes = useStyles();

  if (!data.length) {
    return null;
  }

  const renderItem = (item) => {
    return (
      <HistoryItem>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            {item?.thumbnail ? (
              <img src={item?.thumbnail} className="slider_img" />
            ) : (
              <div className="no_image">
                <MissingImage />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography color="secondary">Sesssion</Typography>
            <Spacer x={1} y={1} />
            <Typography color="textPrimary" sx={{ fontSize: '26px' }}>
              {item?.session?.title}
            </Typography>

            {item?.session?.tags?.length > 0 ? (
              <Stack gap={1} direction="row" mt={1} flexWrap="wrap">
                {item?.session?.tags?.map((item, i) => (
                  <Chip
                    variant="outlined"
                    key={`course-tag-${item?.id || ''}-${i}`}
                    label={`${item?.name}`}
                    sx={{ color: '#7F7F7F', borderRadius: '4px', borderColor: '#7F7F7F' }}
                  />
                ))}
              </Stack>
            ) : null}

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: removeHTML(item?.session?.overviewText),
              }}
            />
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2} mt={2}>
              <Link to={`/lms/session/${item?.session?.id}?play=${item.videoId}`}>
                <Button variant="contained" sx={{ background: '#125151' }}>
                  Continue Watching
                </Button>
              </Link>
              {item?.session?.showRoleplay === true && (
                <Link to={`/lms/session/${item?.session?.id}?roleplay`}>
                  <Button variant="contained" sx={{ background: '#125151' }}>
                    Role-play
                  </Button>
                </Link>
              )}
            </Stack>
          </Grid>
        </Grid>
      </HistoryItem>
    );
  };

  return (
    <HistorySlider>
      {data?.map((item) => (
        <>{renderItem(item)}</>
      ))}
    </HistorySlider>
  );
  return (
    <>
      {data?.length ? (
        <div className={classes.sliderContainer}>
          <HistorySlider>
            {data?.map((item) => (
              <div key={item.id} className="slider_div">
                <div className="slider_content">
                  {item?.thumbnail ? (
                    <img src={item?.thumbnail} className="slider_img" />
                  ) : (
                    <div className="slider_img singleSession_image-div">
                      <MissingImage />
                    </div>
                  )}
                  <div className="slider_text">
                    <p className="slider_session">Session</p>
                    <h2 className="slider_h2">{item?.session?.title}</h2>
                    <div className="slider_pills">
                      {item?.session?.tags?.map((pill, index) => (
                        <div key={index} className="pill">
                          {pill?.name}{' '}
                        </div>
                      ))}
                    </div>
                    <p
                      className="slider_desc"
                      dangerouslySetInnerHTML={{
                        __html: removeHTML(item?.session?.overviewText),
                      }}
                    ></p>
                    <div className="slider_btns">
                      <Link to={`/lms/session/${item?.session?.id}?play=${item.videoId}`}>
                        <button className="slider_btn">Continue Watching</button>
                      </Link>
                      <Link to={`/lms/session/${item?.session?.id}?roleplay`}>
                        <button className="slider_btn">Role-play</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </HistorySlider>
        </div>
      ) : null}
    </>
  );
};

export default WatchHistorySlider;
