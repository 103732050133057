import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { AppGrid } from 'src/components/App';
import { Button } from 'src/components/shared';
import images from 'src/config/images';

import { voiceColumns } from './VoiceColumns';

function VoicesGrid({ drawerToggle, voices, loading, userBulkUpdate, ...props }) {
  const history = useHistory();
  const { data = [], filters, paging, sort = 'status:asc' } = voices;

  const columns = voiceColumns(data, drawerToggle, props);

  const [rowsSelected, setRowsSelected] = useState([]);

  const handleTableChange = ({ pageNo, perPage, searchText, sortOrder }) => {
    props.fetchVoices(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const onSort = (sort) => {
    props.fetchVoices(paging, filters, sort);
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        console.log('key', key, 'value', value);
        props.fetchVoices(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchVoices(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchVoices(paging, filters, sort);
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        onSort={onSort}
        data={data}
        onTableChange={handleTableChange}
        loading={loading?.voices}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'none',
          searchPlaceholder: 'Search tone of voice',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,

          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/voice/create');
                }}
                sx={{
                  borderRadius: '7px',
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #0C1721`,
                  color: '#FFF',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  padding: '0px',
                  margin: '16px 0px',
                }}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <img src={images.icons.plusIcon} alt={''} />
                  <Typography variant="body2" sx={{ padding: '8px 16px 8px 0px' }}>
                    Create new tone of voice
                  </Typography>
                </Stack>
              </Button>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
        }}
      />
    </>
  );
}

export default VoicesGrid;
