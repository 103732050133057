import { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { calculateDaysLeft, changeSRC, checkCompetitionValid } from './helper';

import { getEmbedURL } from 'src/modules/lms/api/LMSApis';

import api from 'src/api';
const sessionContext = createContext({});

export const useSessionProvider = () => {
  const {
    view,
    setView,
    session,
    reports,
    competition,
    videos,
    setVideos,
    progress,
    setProgress,
    rolePlayUrl,
    showRolePlay,
    setShowRolePlay,
    openRoleplay,
    showRoleplayError,
    setShowRoleplayError,
  } = useContext(sessionContext);
  return {
    view,
    setView,
    session,
    reports,
    competition,
    videos,
    setVideos,
    progress,
    setProgress,
    rolePlayUrl,
    showRolePlay,
    setShowRolePlay,
    openRoleplay,
    showRoleplayError,
    setShowRoleplayError,
  };
};

export const SessionStore = ({ children }) => {
  const [view, setView] = useState('desc');
  const [competition, setCompetition] = useState(null);
  const [reports, setReports] = useState([]);
  const [videos, setVideos] = useState([]);
  const [progress, setProgress] = useState({});
  const [rolePlayUrl, setRolePlayUrl] = useState('');
  const [showRolePlay, setShowRolePlay] = useState(false);
  const [showRoleplayError, setShowRoleplayError] = useState(undefined);

  const session = useSelector((state) => state.lms.session);

  const user = useSelector((state) => state.auth.user.id);

  const restoreStore = () => {
    setVideos([]);
    setReports([]);
    setProgress([]);
    setView('desc');
    setCompetition(null);
  };

  useEffect(() => {
    restoreStore();
    if (session && session?.id) {
      getReports();
      getVideos();
      // fetchEmbedUrl();
    }
    if (session && session.videos && session.videos.length) {
      setView('desc');
    } else if (session?.howToGuidePdf && session.howToGuidePdf !== '') {
      setView('guide');
    }
    return () => {
      restoreStore();
    };
  }, [session?.id]);

  const fetchEmbedUrl = async () => {
    try {
      const res = await getEmbedURL(session.id);
      setRolePlayUrl(res.embedURL);
    } catch (error) {
      setRolePlayUrl(undefined);
    }
  };

  const openRoleplay = async () => {
    try {
      const res = await getEmbedURL(session.id);
      setRolePlayUrl(res.embedURL);
      setShowRolePlay(true);
    } catch (error) {
      console.error('Error: ', error);
      setShowRoleplayError(
        error?.error || {
          message: 'Roleplay information is missing',
          key: 'error',
        },
      );
      // setShowRoleplayError({
      //   message: 'Domain and industry is missing from profile',
      //   key: 'settings',
      // });
      return undefined;
    }
  };

  const getReports = async () => {
    let validCompetition = checkCompetitionValid(session, user);
    if (validCompetition) {
      setCompetition(validCompetition);
      let id = validCompetition.id;
      if (id) {
        try {
          const resp = await api(`lms/competition/report/${id}`, null, 'GET');
          if (resp.report.length) setReports(resp.report);
        } catch (err) {}
      }
    }
  };

  const getVideos = () => {
    api(`/lms/session/${session?.id}/video`, null, 'GET')
      .then((res) => {
        let videos =
          res.videos && res.videos.length
            ? res.videos.filter((item) => item.published === true)
            : [];
        if (videos && videos.length) {
          videos = videos.map((item) => {
            const history = (item?.history && item?.history.length && item.history.pop()) || {};
            return {
              ...item,
              ...(history?.watchedDuration && {
                videoUrl: changeSRC(item.videoUrl, history.watchedDuration),
              }),
              history,
              ogVideoUrl: item.videoUrl,
            };
          });
          setVideos(videos);
        }
      })
      .catch((err) => {
        setVideos([]);
      });
  };

  useEffect(() => {
    if (videos && videos.length) {
      const res = videos.reduce((obj, item) => {
        const history = item.history || {};
        const watchedDuration = history.watchedDuration;
        obj = {
          ...obj,
          [item.id]: {
            total: history?.totalDuration || 0,
            watchedDuration,
            isFullyWatched: history?.isFullyWatched,
            progress:
              history?.totalDuration > 0 ? (watchedDuration * 100) / history?.totalDuration : 0,
          },
        };
        return obj;
      }, {});
      setProgress(res);
    }
  }, [videos]);

  return (
    <sessionContext.Provider
      value={{
        view,
        setView,
        session,
        competition,
        reports,
        videos,
        setVideos,
        progress,
        setProgress,
        rolePlayUrl,
        showRolePlay,
        setShowRolePlay,
        openRoleplay,
        showRoleplayError,
        setShowRoleplayError,
      }}
    >
      {children}
    </sessionContext.Provider>
  );
};

export const withSessionStore = (Component) => (props) =>
  (
    <SessionStore>
      <Component {...props} />
    </SessionStore>
  );
