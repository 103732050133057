import React from 'react';
import { Redirect } from 'react-router-dom';
import LMSCompetition from '../components/LMSCompetition/index';
import { connect } from 'react-redux';

const LMSCompetitionContainer = (props) => {
  return <Redirect to="/lms" />;

  return <LMSCompetition {...props} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Add your mapDispatchToProps logic if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(LMSCompetitionContainer);
