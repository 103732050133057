export const themeColors = {
  mainBg: '#090D11',
  bg: '#0C1721',
  text: '#DBE6F6',
  badgeCount: '#EF5089',
  bg2: '#0C1721',
  bg3: '#0C1721',
  yellow: '#FFD47E',
  grey: '#C0C0C0',
  greyText: '#9CA5AE',
  lightBlueText: '#A3CEF9',
  greenText: '#13DEA1',
  greyHeadingText: '#E6E6E6',
  greyParagraphText: '#B3B3B3',
  white: '#EDEDED',
  lightGrey: '#C2C2C2',
  inputBg: '#193247',
  pink: '#EF5089',
  greenButton: '#125151',
  purple: '#553C9A',
  greyHeader: '#5B6777',
  greyHeader: '#5B6777',
  blueWishGrey: '#A0BED9',
  skyBlue: '#99CFFF',
  popUpBlue: '#082035',
  popUpText: '#CCCCCC',
  popUpFields: '#021424',
  popUpChips: '#1B3C59',
  fadedText: '#273947',
};

export const myLearningMenuItems = [
  {
    title: 'My Watch History',
    link: '/lms/watchhistory',
  },
  {
    title: 'My List',
    link: '/lms/list',
  },
];

export const topLinks = [
  { title: 'Sales', link: '/lms' },
  { title: 'Recruitment', link: '/lms/recruitment' },
  // { title: 'Reporting', link: '/lms/dashboard' },
  // { title: 'Competitions', link: '/lms/competition' },
];

export const teamTabs = [
  {
    id: 'users',
    name: 'Users',
  },
  {
    id: 'assigned',
    name: 'Assigned Learning',
  },
  // {
  //   id: 'competitions',
  //   name: 'Competitions',
  // },
];
