import React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import LoginDashboardView from './LoginDashboardView';
// import LandingPageView from "./LandingPageView";

export const WelcomePage = (props) => {
  return (
    <React.Fragment>
      <PageTitle title="Landing Page" />
      {/*<LandingPageView {...props} />*/}
      <LoginDashboardView {...props} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.user,
  newUser: state.auth.newUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
