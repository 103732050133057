import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormHelperText, Box, Typography, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { trim } from 'lodash';
import { fileUpload } from 'src/modules/app/api/appApis';

const apiKey = 'w4kzxwiw7n9w5n5rxt1gv2w3oq30nfwwhyi6r01zmm7rprri';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  characterLimit: PropTypes.number,
  showFontStyle: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  showFontEditor: PropTypes.bool,
  showAlignProps: PropTypes.bool,
  showListProps: PropTypes.bool,
  toolbarLocation: PropTypes.string,
  textColor: PropTypes.string,
  competitiveLearning: PropTypes.bool,
  showAttachments: PropTypes.bool,
  getChildEditorRef: PropTypes.func,
  ref: PropTypes.any,
  isSetup: PropTypes.bool,
  removeSpans: PropTypes.bool,
  pasteAsText: PropTypes.bool,
  height: PropTypes.number,
};

const defaultProps = {
  className: 'custom_input',
  competitiveLearning: false,
  value: undefined,
  textColor: '',
  onChange: () => {},
  loading: false,
  disabled: false,
  characterLimit: 0,
  showFontStyle: true,
  showPlaceholder: true,
  showFontEditor: true,
  showAlignProps: true,
  showListProps: true,
  showAttachments: true,
  toolbarLocation: null,
  getChildEditorRef: () => {},
  ref: null,
  isSetup: true,
  removeSpans: true,
  pasteAsText: false,
  height: 300,
};

const TextEditor = ({
  className,
  onChange,
  value,
  handleBlur = () => {},
  error,
  helperText,
  characterLimit,
  getChildEditorRef,
  ref,
  isSetup,
  textColor,
  removeSpans,
  pasteAsText,
  height,
  competitiveLearning,
  disabled,
  isSignature = false,
  ...props
}) => {
  let replacementVariables = useSelector((state) => state.app.globals.replacementVariables);
  let competitiveLearningVariables = useSelector(
    (state) => state.app.globals.competitionSettings?.replacementVariables,
  );
  const editorRef = React.useRef();

  const theme = useTheme();
  const isDark = theme.palette.type == 'dark';

  const contactVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Contact'));
    }
    return [];
  }, [replacementVariables]);
  const senderVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Sender'));
    }
    return [];
  }, [replacementVariables]);

  const otherVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter(
        (item) => !item.text.includes('Sender') && !item.text.includes('Contact'),
      );
    }
    return [];
  }, [replacementVariables]);

  const [characters, setCharacters] = useState(0);
  const [characterNumber, setCharactersNumber] = useState(0);

  const { unsubscribe = false } = props;

  function applyDefaultFontIfMissing(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const pTag = doc.querySelector('p');
    if (pTag) {
      const currentStyle = pTag.getAttribute('style') || '';
      if (!currentStyle.includes('font-family')) {
        const updatedStyle = `${currentStyle}; font-family: arial;`.trim();
        pTag.setAttribute('style', updatedStyle);
      }
    }
    return doc.body.innerHTML;
  }

  const handleEditorChange = (value) => {
    if (removeSpans === true) {
      value = value.replace(/<span(?:\s[^>]*?)?>((?:.|\n)*?)<\/span>/g, (match, innerContent) => {
        return /style=|class=/.test(match) ? match : innerContent;
      });
    }
    onChange(applyDefaultFontIfMissing(value));
  };

  useEffect(() => {
    const handler = (e) => {
      const { target } = e;
      if (target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        // e.stopPropagation();
        e.stopImmediatePropagation();
        if (target.closest('.tox-textfield') && target.closest('.tox-textfield').length) {
          target.closest('.tox-textfield').focus();
        } else if (target && target.classList.contains('tox-textfield') && target.type === 'url') {
          target.focus();
        }
      }
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  React.useEffect(() => {
    if (getChildEditorRef) {
      getChildEditorRef(editorRef);
    }
  }, [editorRef]);

  useEffect(() => {
    if (value && value !== '' && characterLimit > 0) {
      setCharacters(value.length);

      if (value.length > characterLimit) {
        const newValue = value.substring(0, characterLimit);
        handleEditorChange(newValue);
        // onChange(newValue);
      }
    }
  }, [characterLimit, value]);

  const tools = useMemo(() => {
    const toolStr = [];
    if (props.showFontStyle === true) {
      if (
        props?.showAiWriter === true &&
        props.formType !== 'email' &&
        props.formType !== 'templateEmail'
      ) {
        toolStr.push('aiEmailWrite');
      }
      if (
        props.showPlaceholder === true &&
        props.formType !== 'email' &&
        props.formType !== 'templateEmail'
      ) {
        toolStr.push('placeholder');
      }
      toolStr.push('bold italic');
      if (props?.showUploadIcon === true) {
        toolStr.push('uploadbutton');
      }
      if (props.showPlaceholder === true && props.formType === 'email') {
        toolStr.push('placeholder');
      }
      if (props?.showAiWriter === true && props.formType === 'email') {
        toolStr.push('aiEmailWrite');
      }
      toolStr.push('underline');
      if (props.formType === 'templateEmail') {
        if (props.showFontEditor === true && !isSignature) {
          toolStr.push('forecolor');
        }
        if (props.showPlaceholder === true) {
          toolStr.push('placeholder');
        }
        if (props?.showAiWriter === true) {
          toolStr.push('aiEmailWrite');
        }
        if (props.showFontEditor === true) {
          toolStr.push('fontsize');
          toolStr.push('fontfamily');
        }
      }
    } else {
      if (props?.showUploadIcon === true) toolStr.push('aiEmailWrite');
      if (props?.showAiWriter === true) toolStr.push('aiEmailWrite');
      if (props.showPlaceholder === true) toolStr.push('placeholder');
    }
    if (props.formType !== 'templateEmail') {
      if (props.showFontEditor === true && !isSignature) toolStr.push('forecolor');
      if (props.showFontEditor === true) toolStr.push('fontsize');
      if (props.showFontEditor === true) toolStr.push('fontfamily');
    }
    if (props.showAlignProps === true)
      toolStr.push('alignleft aligncenter alignright alignjustify');
    if (props.showListProps === true) toolStr.push('bullist numlist outdent indent');
    if (props.showAttachments === true) toolStr.push('link image code');
    return toolStr.join(' | ');
  }, [
    props.showFontStyle,
    props.showPlaceholder,
    props.showFontEditor,
    props.showAlignProps,
    props.showListProps,
    props.showAttachments,
  ]);

  var characterLimitHtml = characterLimit > 0 ? characterLimit + 100 : 0;
  const allowed_keys = [8, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40, 46];

  if (props.showPlaceholder === true && (!replacementVariables || !replacementVariables.length))
    return null;

  const setup = function (editor) {
    let toggleState = false;

    editor.ui.registry.addNestedMenuItem('nesteditem', {
      text: 'My nested menu item',
      getSubmenuItems: () => replacementVariables,
    });

    editor.ui.registry.addIcon(
      'overflow-button',
      '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.75 31.75"><defs><style>.cls-1{fill:#4c5866;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Underline"><path class="cls-1" d="M13.88,25.69A10.89,10.89,0,0,1,3,14.82V1.88a1.88,1.88,0,1,1,3.75,0V14.82a7,7,0,0,0,7.13,7.12A7,7,0,0,0,21,14.82V1.88a1.88,1.88,0,1,1,3.75,0V14.82A10.89,10.89,0,0,1,13.88,25.69Z"/><path class="cls-1" d="M25.88,31.75h-24a1.88,1.88,0,1,1,0-3.75h24a1.88,1.88,0,0,1,0,3.75Z"/></g></g></g></svg>',
    );

    editor.on('keydown', function (event) {
      // Detect the "Enter" key press
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default behavior

        // Insert a single <br> tag where the cursor is
        editor.execCommand('InsertLineBreak');
      }
    });

    editor.ui.registry.addMenuButton('placeholder', {
      text: 'Variables',
      class: 'PersonalizeButtonEditor',
      //icon: 'info',
      // tooltip: 'It is a personalize content variable',
      onAction: function () {
        //editor.insertContent('<p>You clicked the main button</p>');
      },
      onItemAction: function (api, value) {
        editor.insertContent(value);
      },
      fetch: function (callback) {
        const items = [
          competitiveLearning === true
            ? {
                type: 'nestedmenuitem',
                text: 'Competitive Learning',
                getSubmenuItems: () =>
                  competitiveLearningVariables.map((item) => ({
                    ...item,
                    type: 'menuitem',
                    onAction: () => editor.insertContent(item.value),
                  })),
              }
            : '',
          {
            type: 'nestedmenuitem',
            text: 'Contact',
            getSubmenuItems: () =>
              contactVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          {
            type: 'nestedmenuitem',
            text: 'Sender',
            getSubmenuItems: () =>
              senderVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          ...otherVariables.map((item) => ({
            ...item,
            type: 'menuitem',
            onAction: () => editor.insertContent(item.value),
          })),
        ];
        callback(items);
      },
    });

    if (props?.showUploadIcon) {
      editor.ui.registry.addIcon(
        'uploadbuttonIcon',
        '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">\n' +
          '  <path d="M9.46148 7.31979V3.27137H10.7246V7.31979H9.46148ZM5.02421 12.5229C4.65186 12.3773 4.34828 12.1373 4.11348 11.8032C3.87867 11.4691 3.76127 11.0894 3.76127 10.664V3.27137H5.02421V12.5229ZM5.82548 16C4.4579 16 3.29691 15.52 2.34253 14.56C1.38814 13.6001 0.91095 12.4364 0.91095 11.0688V3.4979C0.91095 2.52625 1.24835 1.70035 1.92316 1.02021C2.59783 0.340071 3.42099 0 4.39263 0C5.36428 0 6.18751 0.340071 6.86232 1.02021C7.53713 1.70035 7.87453 2.52625 7.87453 3.4979V9.68421H6.61137V3.4899C6.60267 2.868 6.38667 2.3414 5.96337 1.9101C5.54007 1.47881 5.01649 1.26316 4.39263 1.26316C3.77144 1.26316 3.24639 1.48014 2.81748 1.9141C2.38856 2.34821 2.17411 2.87614 2.17411 3.4979V11.0688C2.16541 12.0869 2.51734 12.9528 3.2299 13.6663C3.94246 14.38 4.80779 14.7368 5.8259 14.7368C6.15727 14.7368 6.47207 14.6966 6.77032 14.6162C7.06856 14.5358 7.35025 14.4152 7.61537 14.2543V15.6583C7.33677 15.7706 7.04772 15.8556 6.74821 15.9133C6.44856 15.9711 6.14099 16 5.82548 16ZM9.46148 15.1579V12.7935H7.09727V11.5303H9.46148V9.16611H10.7246V11.5303H13.0891V12.7935H10.7246V15.1579H9.46148Z" fill="#656E83"/>\n' +
          '</svg>',
      );

      editor.ui.registry.addButton('uploadbutton', {
        style: {
          background: 'blue',
        },
        tooltip: 'Upload files',
        icon: 'uploadbuttonIcon',
        onAction: function () {
          props?.handleFileUpload();
        },
      });
    }

    if (props?.showAiWriter) {
      editor.ui.registry.addIcon(
        'aiWriterIcon',
        `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M13.3948 9.52981L14.95 10.3077L13.3948 11.0879L12.6175 12.6401L11.8402 11.0879L10.2851 10.3077L11.8402 9.52981L12.6175 7.97522L13.3948 9.52981ZM4.84287 4.10975L3.2877 3.33246L4.84287 2.55517L5.62016 1L6.39745 2.55517L7.95262 3.33246L6.39745 4.10975L5.62016 5.66492L4.84287 4.10975ZM12.2286 3.7214L10.8682 3.0409L12.2286 2.36041L12.9091 1L13.5896 2.36041L14.95 3.0409L13.5896 3.7214L12.9091 5.08181L12.2286 3.7214Z" fill="#009980"/>
<path d="M1.00027 13.1936C0.992226 12.7306 1.16423 12.2826 1.48004 11.944L6.27109 7.15221L8.81843 9.65705L4.00492 14.4695C3.66622 14.7852 3.21818 14.9571 2.75521 14.9489C2.29225 14.9408 1.85053 14.7532 1.52316 14.4258C1.19578 14.0983 1.00831 13.6566 1.00027 13.1936Z" fill="#009980"/>
<path d="M9.78461 4.87532L11.0742 6.16487C11.4158 6.50648 11.4157 7.06034 11.0741 7.40191L9.48742 8.98821L6.94216 6.48104L8.54759 4.87537C8.88916 4.53374 9.44301 4.53372 9.78461 4.87532Z" fill="#009980"/>
</svg>

                `,
      );
      editor.ui.registry.addButton('aiEmailWrite', {
        text: 'AI-writer',
        icon: 'aiWriterIcon',
        tooltip: 'AI-writer',
        onAction: function () {
          props?.handleAIWriteOnClick(true);
        },
      });
    }

    editor.ui.registry.addButton('unsubscribe', {
      text: '<svg version="1.0" width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#757575" stroke="none"><path d="M620 4464 c-215 -46 -400 -232 -445 -449 -22 -105 -22 -2806 0 -2910 41 -196 199 -371 393 -437 l67 -23 1892 -3 c2076 -3 1965 -6 2095 57 141 67 260 203 308 351 l25 75 0 1435 0 1435 -23 73 c-61 193 -239 355 -437 397 -102 21 -3775 21 -3875 -1z m3822 -314 c63 -18 129 -70 162 -130 28 -49 31 -65 34-155 l3 -100 -1040 -667 -1040 -668 -1041 668 -1041 667 3 100 c3 90 6 106 34 155 32 58 99 112 158 129 45 13 3721 14 3768 1z m-2944 -1417 l1007 -648 55 0 56 0 1007 648 c554 356 1009 647 1012 647 3 0 4 -501 3 -1112 l-3 -1113 -22-41 c-28 -53 -74 -99 -127 -127 l-41 -22 -1864 -3 c-1248 -1 -1876 1 -1901 8-64 17 -130 70 -164 130 l-31 55 -3 1113 c-1 611 0 1112 3 1112 4 0 459 -291 1013 -647z"/></g></svg>',
      tooltip: 'Unsubscribe',
      onAction: function () {
        const selectedContent = editor.selection.getContent();
        editor.insertContent(
          `<a style="text-decoration: unset;" href='##unsubscribe##' target='_blank'>${
            selectedContent || 'unsubscribe'
          }</a>`,
        );
      },
    });
    editor.on('KeyDown', function (ed, evt) {
      if (characterLimit && characterLimit > 0) {
        try {
          const chars_without_html = trim(editor.getContent().replace(/(<([^>]+)>)/gi, '')).length;
          const chars_with_html = editor.getContent().length;
          let key = ed.keyCode;
          setCharacters(chars_without_html);
          if (allowed_keys.indexOf(key) != -1) {
            return;
          }
          if (chars_with_html > characterLimit + characterLimitHtml) {
            ed.stopPropagation();
            ed.preventDefault();
          } else if (chars_without_html > characterLimit - 1 && key != 8 && key != 46) {
            ed.stopPropagation();
            ed.preventDefault();
            let trimmedContent = editor.getContent();
            trimmedContent = editor.getContent().substring(0, characterLimit);
            setCharacters(trimmedContent.length);
            editor.setContent(trimmedContent);
          }
        } catch (error) {}
      }
    });

    editor.on('keyup change', () => {
      const content = editor.getContent({ format: 'text' }).length;

      const charCountFooter = document.getElementById('charCountFooter');
      if (charCountFooter) {
        setCharactersNumber(content);
      }
    });
  };
  const customFonts = `
    Arial=arial,helvetica,sans-serif;
    Times New Roman='Times New Roman',times;
    Courier New=courier new,courier;
    Verdana=verdana,geneva;
    Roboto=Roboto, sans-serif;
    Calibri=Calibri;
  `;
  return (
    <Box sx={{ '& .tox-tinymce': { borderRadius: '4px' } }}>
      {/*{props?.label && characterLimit > 0 ? (*/}
      {/*  <Box display="flex" justifyContent="space-between" p={'0 8px 8px'}>*/}
      {/*    <Typography color="textSecondary" variant="body2">*/}
      {/*      {props?.label}*/}
      {/*    </Typography>*/}
      {/*    <Box>*/}
      {/*      {characterLimit > 0 ? (*/}
      {/*        <Typography>*/}
      {/*          {characters}/{characterLimit}*/}
      {/*        </Typography>*/}
      {/*      ) : null}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*) : null}*/}
      <div style={{ position: 'relative' }}>
        <Editor
          key={props.placeHolder} // Forces re-render when placeHolder changes
          ref={editorRef}
          apiKey={apiKey}
          className={className}
          value={value}
          onBlur={handleBlur}
          onInit={(evt, editor) => (editorRef.current = editor)}
          disabled={disabled}
          init={{
            height: height,
            // forced_root_block: false,
            ...(isDark && {
              skin: 'oxide-dark',
              content_css: 'dark',
            }),
            // valid_styles: { '*': 'font-weight,font-style,text-decoration' },
            // valid_elements: 'a[href|title|target],del,b,strong,del,i,blockquote,p,br,em,ul,li,ol',
            formats: {
              forecolor: isSignature
                ? {
                    inline: 'div',
                    classes: 'forecolor',
                    styles: { display: 'inline', color: '%value' },
                  }
                : {
                    inline: 'span',
                    styles: { color: '%value' },
                  },
              underline: {
                inline: 'u',
              },
              // fontname: {
              //   block: 'p',
              //   styles: { fontFamily: '%value' },
              //   // remove: 'all',
              // },
              // fontsize: {
              //   block: 'p',
              //   styles: { fontSize: '%value' },
              //   // remove: 'all',
              // },
              // fontName: {
              //   block: 'p',
              //   styles: { fontFamily: '%value' },
              //   // remove: 'all',
              // },
              // fontSize: {
              //   block: 'p',
              //   styles: { fontSize: '%value' },
              //   // remove: 'all',
              // },
            },
            // font_formats: 'Calibri=Calibri;',
            font_family_formats: customFonts,
            // menubar: false,
            statusbar: false,
            placeholder: props.placeHolder || '',
            menubar: false,
            content_style: `
        @import url("https://fonts.googleapis.com/css?family=Calibri:300,400,500,700&display=swap");
        body { 
          font-family: arial;
          font-size: 12pt;
          ${textColor ? 'color:' + textColor : ''};
          background-color: ${isDark ? '#021424' : '#ffffff'};
        }
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
          font-weight: 400;
          font-size: 12pt;
          font-family: arial;
          letter-spacing: 0.15px;
        }
      `,
            plugins: [
              'textcolor',
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
              'colorPicker',
            ],
            paste_as_text: pasteAsText,
            selector: 'textarea',
            toolbar: tools || '',
            // toolbar1: tools || '',
            forced_root_block: false, // Prevent wrapping in <p> tags
            force_br_newlines: true, // Insert <br> for new lines
            force_p_newlines: false, // Prevent paragraph insertion for new lines
            toolbar_location: props?.toolbarLocation ? props?.toolbarLocation : 'top',
            images_upload_handler: async (blobInfo, success, failure, progress) => {
              return new Promise(async (resolve, reject) => {
                try {
                  const upload = await fileUpload('', blobInfo.blob(), (event) => {
                    progress(Math.round((100 * event.loaded) / event.total));
                  });
                  const url = upload.upload.url;
                  resolve(url);
                  success(url);
                } catch (e) {
                  reject(e);
                  failure(e);
                }
              });
            },
            setup: isSetup ? setup : () => {},
          }}
          onEditorChange={handleEditorChange}
        />
        {characterLimit ? (
          <div
            id="charCountFooter"
            style={{
              textAlign: 'right',
              padding: '8px',
              fontSize: '12px',
              color: '#555',
              position: 'absolute',
              right: '0px',
              bottom: '70px',
            }}
          >
            {`${characterNumber}/${characterLimit}`}
          </div>
        ) : null}
      </div>
      {error === true ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
    </Box>
  );
};

TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;
