import React from 'react';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';
import { dataProviderReport, consultantReport } from '../../config/data';
import { useDataProviders } from '../../utils/dataProviderStore';
import SummaryCard from '../../components/DataCredit/SummaryCard';
import ProgressChart from '../../components/DataCredit/ProgressChart';
import BarChart from '../../components/DataCredit/BarChart';
import ConsultantReport from '../../components/DataCredit/ConsultantReport';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../utils/helper';

function Summary() {
  const { summary, loading, setFilters, filters, fetchSummary } = useDataProviders();
  const tenant = useSelector((state) => state.app.tenant);

  const filtersArr = [
    {
      key: 'createdAt',
      title: 'Filter By Date',
      type: 'dateRange',
      isPrimary: true,
    },
  ];

  React.useEffect(() => {
    fetchSummary();
  }, []);

  const { cards, credits, thisMonth, lastMonth, users } = summary;
  const purchasedOn = tenant?.creditsPurchased?.data?.purchasedOn
    ? new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(tenant?.creditsPurchased?.data?.purchasedOn))
    : '';

  return (
    <>
      <FilterBar
        filters={filtersArr}
        appliedFilters={filters}
        onChange={(key, value) => {
          setFilters({
            [key]: value,
          });
        }}
      />

      <Spacer y={2} />

      <Stack
        display={'grid'}
        gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'}
        gap={'12px'}
        sx={{
          '& > div > div': {
            height: '100%',
          },
        }}
      >
        <SummaryCard
          loading={loading.summary}
          title="Credit Balance"
          value={`${tenant?.credits ? formatCurrency(tenant?.credits) : 0}`}
        />
        <SummaryCard
          loading={loading.summary}
          title="Credit Used"
          subTitle="Last 30 days"
          value={`${formatCurrency(cards?.creditsUsed)}`}
          // value={`${tenant?.creditUsedThisMonth ? Math.round(tenant?.creditUsedThisMonth) : 0}`}
        />
        <SummaryCard
          loading={loading.summary}
          title="Last Purchase"
          subTitle={purchasedOn}
          value={`${(tenant?.creditsPurchased?.data?.price || 0).toFixed(2)}`}
        />
        <SummaryCard
          title={
            <Stack
              width="100%"
              alignItems={'center'}
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Typography variant="body2" color="textSecondary">
                Work emails found
              </Typography>
              <Tooltip
                title={'Indicates successful work email results, not total attempts.'}
                placement="bottom"
              >
                <InfoIcon style={{ fill: '#a3a3a3', width: '20px' }} />
              </Tooltip>
            </Stack>
          }
          hideCurrency
          loading={loading.summary}
          value={`${cards.workEmails}`}
        />
        <SummaryCard
          title={
            <Stack
              width="100%"
              alignItems={'center'}
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Typography variant="body2" color="textSecondary">
                Personal emails found
              </Typography>
              <Tooltip
                title={'Indicates successful personal email matches, not all attempts.'}
                placement="bottom"
              >
                <InfoIcon style={{ fill: '#a3a3a3', width: '20px' }} />
              </Tooltip>
            </Stack>
          }
          hideCurrency
          loading={loading.summary}
          value={`${cards.personalEmails}`}
        />
        <SummaryCard
          title={
            <Stack
              width="100%"
              alignItems={'center'}
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Typography variant="body2" color="textSecondary">
                Numbers found
              </Typography>
              <Tooltip
                title={'Indicates successful phone number retrievals, not total requests.'}
                placement="bottom"
              >
                <InfoIcon style={{ fill: '#a3a3a3', width: '20px' }} />
              </Tooltip>
            </Stack>
          }
          hideCurrency
          value={`${cards.phone}`}
          loading={loading.summary}
        />
      </Stack>

      <Spacer x={2} y={2} />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProgressChart
            title="Current month"
            total={thisMonth?.total || 0}
            value={thisMonth?.used || 0}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ProgressChart
            title="Last month"
            total={lastMonth?.total || 0}
            value={lastMonth?.used || 0}
          />
        </Grid>
      </Grid>
      <Spacer x={2} y={2} /> */}

      <BarChart
        title="Credit usage by data provider"
        loading={loading.summary}
        data={dataProviderReport}
        credits={credits}
      />
      <Spacer x={2} y={2} />

      <ConsultantReport data={users} loading={loading.summary} />
      <Spacer x={4} y={4} />
    </>
  );
}

export default Summary;
