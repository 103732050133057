import Dashboard from '../container/dashboard';
import Home from '../container/home';
import ActiveReport from '../container/activeReport';
import Onboarding from '../container/onboarding';

import Boards from '../container/boards';
import Demo from '../container/Demo';
import OutReachJourney from '../components/OutReachJourney';
// import WelcomePage from '../container/loginDashboard';

export default [
  {
    title: 'Demo',
    component: Demo,
    url: '/demo',
    exact: true,
    auth: true,
  },
  {
    title: 'Boards',
    component: Boards,
    url: '/boards',
    exact: true,
    auth: true,
  },
  {
    title: 'Boards',
    component: Boards,
    url: '/boards/:boardId',
    exact: true,
    auth: true,
  },
  {
    title: 'Dashboard',
    component: Home,
    url: '/',
    exact: true,
    auth: true,
  },
  {
    title: 'Dashboard',
    component: Dashboard,
    url: '/dashboard',
    exact: true,
    auth: true,
  },
  {
    title: 'Activity Reports',
    component: ActiveReport,
    url: '/activity-report',
    exact: true,
    auth: true,
  },
  {
    title: 'Outreach Video Library',
    component: Onboarding,
    url: '/onboarding',
    exact: true,
    auth: true,
  },
  {
    title: 'Outreach Journey',
    component: OutReachJourney,
    url: '/outreach-journey',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'Landing Page',
  //   component: WelcomePage,
  //   url: '/landing',
  //   exact: true,
  //   auth: true,
  // },
];
