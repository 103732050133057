import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { IconButton, Menu, MenuItem, Fade, ButtonBase, Typography } from "@mui/material";
import { InputAdornment, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CodeIcon from "@mui/icons-material/Code";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
import NestedMenuItem from "./nestedMenu";
import { useStyles } from "./styles";
import { helperTextValue } from "../ErrorHelperText";

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  filter: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  loading: PropTypes.bool,
  showPicker: PropTypes.bool,
  pickerIcon: PropTypes.any,
  options: PropTypes.array,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  emojiPicker: PropTypes.bool,
  color: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  filter: undefined,
  onChange: () => {},
  variant: "standard",
  loading: false,
  showPicker: false,
  options: [],
  optLabel: "label",
  optValue: "value",
  emojiPicker: false,
  pickerIcon: <CodeIcon color="secondary" />,
  color: "secondary",
};

function EmojiPicker(emojiProps) {
  const emojiRef = useRef();

  useEffect(() => {
    new Picker({ ...emojiProps, data, ref: emojiRef });
  }, []);

  return <div ref={emojiRef} />;
}

const StyledMenu = withStyles({
  paper: {
    background: "transparent",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    TransitionComponent={Fade}
    {...props}
  />
));

const InputComponent = forwardRef(
  (
    {
      icon,
      className,
      filter,
      onChange,
      variant,
      helperText,
      error,
      id,
      loading,
      type,
      InputProps,
      disabled,
      showPicker,
      options,
      optLabel,
      optValue,
      emojiPicker,
      pickerIcon,
      color,
      notched,
      page,
      errorWithIcon,
      errorBorder,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ errorBorder: errorBorder || false, error });

    const [showPassword, setShowPassword] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);
    const [cursorPos, setCursorPos] = React.useState(0);
    const positionRef = useRef();
    positionRef.current = cursorPos;

    const inputRef = useRef(null);

    const handleChange = (event) => {
      if (!filter || filter.test(event.target.value)) {
        onChange(event.target.value, event);
      }
    };

    const inputProps = {
      ...InputProps,
      endAdornment: (
        <>
          {showPicker && (
            <InputAdornment position="end">
              <ButtonBase
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {pickerIcon}
              </ButtonBase>
            </InputAdornment>
          )}
          {loading && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          )}
          {type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                size="large"
              >
                {showPassword ? <VisibilityOffOutlinedIcon size={20} /> : <VisibilityOutlinedIcon size={20} />}
              </IconButton>
            </InputAdornment>
          )}

          {emojiPicker && (
            <InputAdornment position="end">
              <ButtonBase
                onClick={(event) => {
                  setAnchorElEmoji(event.currentTarget);
                }}
              >
                <InsertEmoticonIcon color="secondary" />
              </ButtonBase>
            </InputAdornment>
          )}
          {InputProps?.endAdornment ? InputProps?.endAdornment : null}
        </>
      ),
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
      changeWithCursor(option[optValue]);
      setCursorPos((cursorPos) => cursorPos + option[optValue].length);
    };

    const onEmojiSelect = (emoji) => {
      changeWithCursor(emoji.native);
      setAnchorElEmoji(null);
    };

    const changeWithCursor = (contentToCopy) => {
      const cursorPos = positionRef.current;
      const currentValue = inputRef.current.value;
      let newValue = "";
      if (cursorPos > 0) {
        newValue = `${currentValue.slice(0, cursorPos)}${contentToCopy}${currentValue.slice(cursorPos)}`;
      } else {
        newValue = `${contentToCopy}${currentValue}`;
      }
      onChange(newValue, null);
      setAnchorEl(null);
    };

    const handleCursorChange = (event) => {
      if (typeof event.target.selectionStart !== "undefined") {
        setCursorPos(event.target.selectionStart);
      }
    };

    const onKeyUp = (event) => {
      handleCursorChange(event);
    };

    const handleBlur = (event) => {};

    const handleFocus = (event) => {};

    const contactVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter((item) => item.text.includes("Contact"));
      }
      return [];
    }, [options]);
    const senderVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter((item) => item.text.includes("Sender"));
      }
      return [];
    }, [options]);
    const otherVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter((item) => !item.text.includes("Sender") && !item.text.includes("Contact"));
      }
      return [];
    }, [options]);

    const filteredOptions = [
      {
        type: "nestedMenuItem",
        text: "Contact",
        getSubmenuItems: () =>
          contactVariables.map((item) => ({
            ...item,
            type: "menuItem",
          })),
      },
      {
        type: "nestedMenuItem",
        text: "Sender",
        getSubmenuItems: () =>
          senderVariables.map((item) => ({
            ...item,
            type: "menuItem",
          })),
      },
      ...otherVariables.map((item) => ({
        ...item,
        type: "menuItem",
      })),
    ];

    return (
      <>
        {page === "auth" ? (
          <TextField
            className={`${className} ${classes.root}`}
            type={type === "password" && showPassword ? "text" : type}
            onChange={handleChange}
            ref={ref}
            variant={variant}
            helperText={
              props?.hideHelperText
                ? null
                : helperText
                ? errorWithIcon
                  ? helperTextValue({ helperText })
                  : helperText
                : null
            }
            error={error}
            disabled={loading || disabled}
            id={id}
            InputProps={inputProps}
            {...props}
          />
        ) : (
          <>
            <TextField
              className={className}
              type={type === "password" && showPassword ? "text" : type}
              onChange={handleChange}
              inputRef={inputRef}
              variant={variant}
              helperText={
                props?.hideHelperText
                  ? null
                  : helperText
                  ? errorWithIcon
                    ? helperTextValue({ helperText })
                    : helperText
                  : null
              }
              sx={
                props?.isSmall
                  ? {
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "14px",
                      },
                      "&.MuiOutlinedInput-root": { fontSize: "14px", lineHeight: "24px" },
                    }
                  : {}
              }
              InputLabelProps={
                props?.isSmall
                  ? {
                      sx: {
                        fontSize: "14px",
                      },
                    }
                  : {}
              }
              // sx={{ '&.MuiOutlinedInput-root': { fontSize: '14px', lineHeight: '24px' } }}
              error={error}
              id={id}
              InputProps={inputProps}
              disabled={loading || disabled}
              color={color}
              onKeyUp={onKeyUp}
              onClick={onKeyUp}
              // InputLabelProps={{
              //   shrink: typeof props.value !== 'undefined' && props.value !== '' && props.value,
              // }}
              // notched={false}

              {...props}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
            {showPicker ? (
              <Menu
                id={`${id}-picker`}
                anchorEl={anchorEl}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {filteredOptions.length ? (
                  filteredOptions.map((option, index) => {
                    if (option?.type === "menuItem")
                      return (
                        <MenuItem
                          key={`${id}-picker-option-${index}`}
                          value={option[optValue]}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option[optLabel]}
                        </MenuItem>
                      );
                    else if (option?.type === "nestedMenuItem")
                      return (
                        <NestedMenuItem
                          label={option[optLabel]}
                          rightAnchored={true}
                          parentMenuOpen={Boolean(anchorEl)}
                        >
                          {option?.getSubmenuItems()?.map((nestedOption, index) => (
                            <MenuItem
                              key={`${id}-picker-option-${index}`}
                              value={nestedOption[optValue]}
                              onClick={() => handleOptionClick(nestedOption)}
                            >
                              <Typography>{nestedOption[optLabel]}</Typography>
                            </MenuItem>
                          ))}
                        </NestedMenuItem>
                      );
                  })
                ) : (
                  <MenuItem disabled>
                    <Typography>No options.</Typography>
                  </MenuItem>
                )}
              </Menu>
            ) : null}

            {emojiPicker ? (
              <StyledMenu
                id={`${id}-emoji-picker`}
                anchorEl={anchorElEmoji}
                keepMounted
                open={Boolean(anchorElEmoji)}
                onClose={() => {
                  setAnchorElEmoji(null);
                }}
              >
                <EmojiPicker onEmojiSelect={onEmojiSelect} />
              </StyledMenu>
            ) : null}
          </>
        )}
      </>
    );
  }
);

InputComponent.propTypes = propTypes;
InputComponent.defaultProps = defaultProps;

export default InputComponent;
