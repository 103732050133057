import styled from 'styled-components';
import { Typography } from '@mui/material';

import { getColorCode } from '../../utils/sequenceHelper';
import { getSequenceStatusColorCode } from "../../../contacts/utils/contactUtils";

export const ContactStatus = styled.div`
  display: flex;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  gap: 6px;
  height: 30px;
  width: fit-content;
  background-color: ${(props) => getColorCode(props.status.toLowerCase()).bgColor};
  min-width: 118px;
`;

export const ContactStatusLabel = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.16px;
  color: ${(props) => getColorCode(props.status.toLowerCase()).color};
`;
export const StyledFilterCard = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid ${(props) => (props?.activeCard ? '#1976D2' : 'rgba(0, 0, 0, 0.12)')};
  background: ${(props) => (props?.activeCard ? 'rgba(144, 202, 249, 0.16)' : '#fff')};
`;
export const StyledFilterCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const StyledFilterCardTitle = styled(Typography)`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.17px;
  text-align: center;
  text-wrap: nowrap;
`;

export const StyledFilterCardValue = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.17px;
`;
