import * as React from 'react';
import { Box, Button, FormHelperText } from '@mui/material';
import { useStyles } from './styles';

export default function SeqTypeTab({ seqTypeList, value, onClick, buttonStyle, error }) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        {seqTypeList?.map((item) => (
          <>
            <Button
              onClick={() => onClick(item?.value)}
              sx={buttonStyle || {}}
              className={`${classes.button} ${value === item?.value && classes.activeButton}`}
            >
              {item?.label}
            </Button>
          </>
        ))}
      </Box>
      {error && (
        <FormHelperText
          sx={(theme) => ({
            lineHeight: 'normal',
            color: `#D32F2F !important`,
            fontSize: '0.75rem',
            margin: '4px 14px 0 14px',
          })}
        >
          {error}
        </FormHelperText>
      )}
    </>
  );
}
